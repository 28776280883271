<template>
  <nav class="nav">
    <div class="inner">
      <div class="btn_wrap">
        <div class="btn_box">
          <img src="@/assets/static/images/i_speakerphone.svg" alt="icon" />
          <span class="txt">2021년 7월 고지서부터 확인하실 수 있습니다. </span>
        </div>
      </div>
    </div>
  </nav>
  <bill-li-list-index :items="items" />
  <div v-observe-visibility="visibilityChanged" />
</template>

<script>
import BillLiListIndex from '@/components/views/bill/li-list'
import { getCurrentInstance, reactive, toRefs } from 'vue'

export default {
  name: 'bill-index',
  components: { BillLiListIndex },
  description: '고지서 확인 페이지',
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      params: {
        page: 1,
        limit: 10
      },
      items: [],
      pageInfo: []
    })

    const fnListBox = async () => {
      try {
        const res = await proxy.$BillSvc.fetchUserBillList(state.params)
        if (res.code === 1) {
          state.items = state.items.concat(res.entity.list)
          state.pageInfo = res.entity.pageInfo
        }
      } catch (e) {
        console.log(e)
      }
    }

    const visibilityChanged = async isVisible => {
      if (isVisible) {
        if (state.pageInfo.totalPage < state.params.page) return false
        await fnListBox()
      }
    }

    // fnListBox()
    return { ...toRefs(state), fnListBox, visibilityChanged }
  }
}
</script>

<style scoped></style>
