<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" :class="{ page_bg: $Util.isEmpty(items) }">
          <div class="list_page" v-if="!$Util.isEmpty(items)">
            <div class="box_con">
              <div class="box_list_wrap">
                <div
                  class="box_list border flex_box"
                  v-for="(item, index) in items"
                  :key="index"
                  @click="fnPage(item.bill.billNo)"
                >
                  <p>{{ item.bill.year }}년 {{ item.bill.month }}월</p>
                  <div class="icon"></div>
                </div>
              </div>
            </div>
          </div>
          <empty-index :content="'고지서가 없습니다.'" v-else />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { useRouter } from 'vue-router'
import EmptyIndex from '@/components/views/empty'

export default {
  name: 'bill-li-list-index',
  description: '고지서 리스트',
  components: { EmptyIndex },
  props: {
    items: {
      type: [Array],
      default: () => {}
    }
  },
  setup() {
    const router = useRouter()

    const fnPage = item => {
      router.push({ path: '/bill/view', query: { billNo: item } })
    }

    return { fnPage }
  }
}
</script>

<style scoped></style>
